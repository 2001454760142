html, body, #root {
  padding-top: 5px;
}

#app {
  max-width: 600px;
  padding: 20px;
}

img.logo {
  max-width: 80px;
}

.clover-footer {
  width: 100%;
  padding: 10px 24px !important;
  border-radius: 0 !important;
}

.clover-input {
  height: 40px;
  margin: 10px 0 0;
}
